import React, { useEffect, useRef } from 'react';
import { Github } from 'lucide-react';

const Footer = () => {
  const footerRef = useRef(null);

  useEffect(() => {
    const footer = footerRef.current;
    let offset = 0;

    const animate = () => {
      offset += 0.3;
      footer.style.backgroundImage = `linear-gradient(135deg, 
        hsl(220, 30%, 20%) 25%, 
        hsl(220, 30%, 22%) 25%, 
        hsl(220, 30%, 22%) 50%, 
        hsl(220, 30%, 20%) 50%, 
        hsl(220, 30%, 20%) 75%, 
        hsl(220, 30%, 22%) 75%, 
        hsl(220, 30%, 22%) 100%)`;
      footer.style.backgroundSize = '40.00px 40.00px';
      footer.style.backgroundPosition = `${offset}px ${offset}px`;
      requestAnimationFrame(animate);
    };

    animate();
  }, []);

  return (
    <footer ref={footerRef} className="text-white p-4 mt-auto transition-all duration-300 ease-in-out">
      <div className="container mx-auto flex flex-col items-center">
        <p className="font-mono mb-2 text-center">
          <span className="text-green-400">if</span> (year === <span className="text-yellow-300">2024</span>) {'{'} 
          <br />
          &nbsp;&nbsp;copyright = <span className="text-blue-300">"DeadCodeStudios"</span>;
          <br />
          {'}'}
        </p>
        <div className="flex items-center">
          <a href="https://github.com/DeadCodeStudios" target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-400 transition-colors duration-200">
            <Github size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
