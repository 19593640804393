import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import projects from '../data/projects.json';
import timelineEvents from '../data/timeline.json';

const GlitchText = ({ text }) => {
  const [glitchedText, setGlitchedText] = useState(text);
  
  useEffect(() => {
    const glitchInterval = setInterval(() => {
      const glitchChars = '!<>-_\\/[]{}—=+*^?#________';
      const newText = text.split('').map(char => 
        Math.random() < 0.1 ? glitchChars[Math.floor(Math.random() * glitchChars.length)] : char
      ).join('');
      setGlitchedText(newText);
    }, 200);

    return () => clearInterval(glitchInterval);
  }, [text]);

  return <span>{glitchedText}</span>;
};

const ProjectCard = ({ project, index }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setIsTransitioning(true);
    setTimeout(() => {
      window.open(project.url, '_blank');
      setIsTransitioning(false);
    }, 1500);
  };

  return (
    <motion.div
      className="relative w-full h-96"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <AnimatePresence>
        {!isTransitioning && (
          <motion.div
            className="w-full h-full"
            animate={{ rotateY: isFlipped ? 180 : 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            style={{ transformStyle: 'preserve-3d' }}
            onHoverStart={() => setIsFlipped(true)}
            onHoverEnd={() => setIsFlipped(false)}
            exit={{ opacity: 0, scale: 0.8, transition: { duration: 0.3 } }}
          >
            {/* Front side */}
            <motion.div
              className="absolute w-full h-full backface-hidden rounded-lg shadow-lg overflow-hidden bg-gray-800 border-2 border-green-400"
              style={{ backfaceVisibility: 'hidden' }}
            >
              <img src={project.image} alt={project.title} className="w-full h-64 object-cover" />
              <h3 className="text-lg font-mono font-semibold mt-2 p-4 text-green-400">
                {project.title}
              </h3>
            </motion.div>

            {/* Back side */}
            <motion.div 
              className="absolute w-full h-full backface-hidden rounded-lg shadow-lg overflow-hidden bg-gray-900 border-2 border-green-400 p-6 flex flex-col justify-between"
              style={{ backfaceVisibility: 'hidden', transform: 'rotateY(180deg)' }}
            >
              <div>
                <h3 className="text-xl font-mono font-bold mb-4 text-green-400">
                  {project.title} 
                </h3>
                <p className="text-gray-300 mb-4">{project.description}</p>
              </div>
              <div>
                <h4 className="text-sm font-semibold mb-2 text-green-400">Made by:</h4>
                <ul className="list-disc list-inside text-sm text-gray-300">
                  {project.contributors.map((contributor, i) => (
                    <li key={i}>{contributor}</li>
                  ))}
                </ul>
                <motion.button
                  className="mt-4 inline-block bg-green-400 text-gray-900 py-2 px-4 rounded hover:bg-green-300 transition-colors duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleClick}
                >
                  View Project
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      
      <AnimatePresence>
        {isTransitioning && (
          <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.img
              src={project.image}
              alt={project.title}
              className="object-cover"
              initial={{ width: '300px', height: '200px', borderRadius: '8px' }}
              animate={{ 
                width: '100vw', 
                height: '100vh', 
                borderRadius: '0px',
                transition: { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }
              }}
            />
            <motion.div
              className="absolute inset-0 bg-green-400"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.3 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            />
            <motion.h2
              className="absolute text-4xl font-bold text-white text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7, duration: 0.5 }}
            >
              {project.title}
            </motion.h2>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const Timeline = () => {
  const [currentEventIndex, setCurrentEventIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentEventIndex((prevIndex) => 
        prevIndex === timelineEvents.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="mb-16 p-8 bg-gray-800 rounded-lg shadow-lg overflow-hidden">
      <h3 className="text-2xl font-mono font-semibold mb-4 text-green-400">
        &gt; Unsere_Historie_
      </h3>
      <div className="relative h-64">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentEventIndex}
            className="absolute inset-0 flex flex-col justify-center items-center text-center"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 1.2 }}
            transition={{ duration: 0.5 }}
          >
            <p className="text-xl font-mono text-white mb-2">
              <GlitchText text={timelineEvents[currentEventIndex].date} />
            </p>
            <p className="text-2xl font-mono text-green-400">
              <GlitchText text={timelineEvents[currentEventIndex].event} />
            </p>
          </motion.div>
        </AnimatePresence>
      </div>
      <div className="flex justify-center mt-4">
        {timelineEvents.map((_, index) => (
          <motion.div
            key={index}
            className={`w-3 h-3 mx-1 rounded-full cursor-pointer ${
              index === currentEventIndex ? 'bg-green-400' : 'bg-gray-600'
            }`}
            whileHover={{ scale: 1.5 }}
            onClick={() => setCurrentEventIndex(index)}
          />
        ))}
      </div>
    </section>
  );
};

const Home = () => {
  return (
    <main className="flex flex-col min-h-screen p-8 text-center bg-gray-900">
      <Helmet>
        <title>Home - DeadCodeStudios</title>
      </Helmet>
      <motion.h2
        className="text-3xl font-bold mb-4 text-white"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        Willkommen bei{' '}
        <motion.span
          className="text-green-400"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          DeadCodeStudios
        </motion.span>
        !
      </motion.h2>
      <p className="text-lg mb-8 text-gray-400">
        Hier experimentieren wir mit Trash Coding Projekten aus Spaß und Langeweile.
      </p>

      <section className="mb-16">
        <h3 className="text-2xl font-semibold mb-4 text-white">Unsere Projekte</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {projects.map((project, index) => (
            <ProjectCard key={index} project={project} index={index} />
          ))}
        </div>
      </section>

      <Timeline />
    </main>
  );
};

export default Home;