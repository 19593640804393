import React from 'react';
import { motion } from 'framer-motion';
import { teamMembers } from '../data/teamData';
import { Helmet } from 'react-helmet';

const Team = () => {
  return (
    <div className="bg-gray-900 min-h-screen text-white py-16 px-4 sm:px-6 lg:px-8">
      {/* Helmet für den Tab-Titel */}
      <Helmet>
        <title>Team - DeadCodeStudios</title>
      </Helmet>
      <motion.h2 
        className="text-4xl font-bold text-center mb-12"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Our Team
      </motion.h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        {teamMembers.map((member, index) => (
          <motion.div 
            key={member.id}
            className="bg-gray-800 rounded-lg overflow-hidden shadow-lg"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            whileHover={{ scale: 1.05 }}
          >
            <img src={member.image} alt={member.name} className="w-full h-64 object-cover" />
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2">{member.name}</h3>
              <p className="text-green-400">{member.role}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Team;