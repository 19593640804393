// src/data/teamData.js

export const teamMembers = [
    {
      id: 1,
      name: "Luka Petrovic",
      role: "Full-Stack Developer / Founder",
      image: "/images/dummy.jpg"
    },
    {
      id: 2,
      name: "Dummy",
      role: "Backend Developer",
      image: "/images/dummy.jpg"
    },
    {
      id: 3,
      name: "Dummy",
      role: "UI/UX Designer",
      image: "/images/dummy.jpg"
    },
    {
      id: 4,
      name: "Dummy",
      role: "Dummy Role",
      image: "/images/dummy.jpg"
    }
  ];