import React from 'react';
import { motion } from 'framer-motion';
import {Helmet} from 'react-helmet';

const Contact = () => {
  return (
    <div className="bg-gray-900 min-h-screen text-white py-16 px-4 sm:px-6 lg:px-8 flex items-center justify-center">
      {/* Helmet für den Tab-Titel */}
      <Helmet>
        <title>Contact - DeadCodeStudios</title>
      </Helmet>
      <motion.div 
        className="bg-gray-800 p-8 rounded-lg shadow-2xl max-w-md w-full"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.h2 
          className="text-3xl font-bold mb-6 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          Contact Us
        </motion.h2>
        <motion.p 
          className="text-center mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          Feel free to reach out to us at:
        </motion.p>
        <motion.a 
          href="mailto:contact@deadcodestudios.com"
          className="block text-center text-2xl text-green-400 hover:text-green-300 transition-colors duration-300"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
          whileHover={{ scale: 1.05 }}
        >
          contact@deadcodestudios.com
        </motion.a>
      </motion.div>
    </div>
  );
};

export default Contact;