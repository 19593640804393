import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const Header = () => {
  const [text, setText] = useState('DeadCodeStudios');
  const [isHovered, setIsHovered] = useState(false);
  const fullText = 'DeadCodeStudios';
  const glitchChars = '!<>-_\\/[]{}—=+*^?#________';
  const location = useLocation();

  useEffect(() => {
    if (!isHovered) {
      let interval = setInterval(() => {
        setText(current => {
          if (Math.random() < 0.1) {
            let result = '';
            for (let i = 0; i < fullText.length; i++) {
              if (Math.random() < 0.1) {
                result += glitchChars[Math.floor(Math.random() * glitchChars.length)];
              } else {
                result += fullText[i];
              }
            }
            return result;
          }
          return fullText;
        });
      }, 100);

      return () => clearInterval(interval);
    }
  }, [isHovered]);

  const NavLink = ({ to, children }) => (
    <Link to={to} className="ml-4 text-green-400 hover:text-green-300 transition-colors duration-200">
      <span className="font-mono">&gt; {children}</span>
    </Link>
  );

  const variants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
  };

  const homeVariants = {
    visible: {
      rotate: [-2, 2, -2],
      transition: {
        rotate: {
          repeat: Infinity,
          duration: 1,
          ease: "easeInOut"
        }
      }
    }
  };

  return (
    <header className="sticky top-0 bg-gray-800 text-white p-6 z-50 flex justify-between items-center">
      <Link 
        to="/"
        className="relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <AnimatePresence mode="wait">
          <motion.h1 
            key={isHovered && location.pathname !== '/' ? 'home' : 'normal'}
            variants={isHovered && location.pathname !== '/' ? homeVariants : variants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            className="text-2xl font-mono font-bold tracking-wider py-2 px-4 rounded hover:bg-gray-700"
          >
            <span className="text-green-400">&gt;</span>
            {isHovered && location.pathname !== '/' ? 'Home?' : text}
            <span className="text-green-400 animate-pulse">_</span>
          </motion.h1>
        </AnimatePresence>
      </Link>
      <nav>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/projects">Projects</NavLink>
        <NavLink to="/contact">Contact</NavLink>
        <NavLink to="/team">Team</NavLink>
      </nav>
    </header>
  );
};

export default Header;