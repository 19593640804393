import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const TypeWriter = ({ text, delay = 50 }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [currentIndex, delay, text]);

  return <span>{displayText}</span>;
};

const About = () => {
  const [bootPhase, setBootPhase] = useState(0);

  useEffect(() => {
    const bootSequence = [
      { delay: 1000, phase: 1 },
      { delay: 2000, phase: 2 },
      { delay: 2000, phase: 3 },
    ];

    bootSequence.forEach(({ delay, phase }, index) => {
      setTimeout(() => setBootPhase(phase), delay + (index * 1000)); // Added index * 1000 to stagger
    });
  }, []);

  return (
    <section className="p-8 bg-black min-h-screen text-green-400 font-mono">
      {/* Helmet für den Tab-Titel */}
      <Helmet>
        <title>About - DeadCodeStudios</title>
      </Helmet>
      <motion.div 
        className="max-w-3xl mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        {bootPhase >= 1 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <p>&gt; Initiating system boot...</p>
            <p>&gt; Loading DeadCodeStudios OS v1.0.0...</p>
          </motion.div>
        )}

        {bootPhase >= 2 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <p>&gt; System loaded successfully.</p>
            <p>&gt; Starting about.txt...</p>
          </motion.div>
        )}

        {bootPhase >= 3 && (
          <motion.div
            className="mt-8 bg-gray-900 p-6 rounded-lg shadow-lg border border-green-400"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-2xl font-bold mb-4">&gt; Über_DeadCodeStudios_</h2>
            <TypeWriter 
              text="DeadCodeStudios ist ein digitaler Spielplatz für kreative Coding-Projekte, die aus einer Mischung von Langeweile, Neugier und purem Spaß am Experimentieren entstehen."
              delay={30}
            />
            <br /><br />
            <TypeWriter 
              text="Unser Ziel? Durch 'Trash Coding' die Grenzen des Möglichen zu erkunden und dabei vielleicht versehentlich etwas Geniales zu erschaffen."
              delay={30}
            />
            <br /><br />
            <TypeWriter 
              text="Wir glauben daran, dass in jedem Stück 'totem Code' das Potenzial für etwas Außergewöhnliches steckt. Also, tauchen Sie ein in unsere Welt der digitalen Alchemie!"
              delay={30}
            />
          </motion.div>
        )}
      </motion.div>
    </section>
  );
};

export default About;
