import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowLeft, ExternalLink } from 'lucide-react';
import { Helmet } from 'react-helmet';
import projects from '../data/projects.json';

const GlitchText = ({ text }) => {
  const [glitch, setGlitch] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setGlitch(true);
      setTimeout(() => setGlitch(false), 200);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <span className={`relative inline-block ${glitch ? 'animate-glitch' : ''}`}>
      {text}
      {glitch && (
        <>
          <span className="absolute top-0 left-0.5 text-red-500 opacity-70">{text}</span>
          <span className="absolute -top-0.5 -left-0.5 text-blue-500 opacity-70">{text}</span>
        </>
      )}
    </span>
  );
};

const ProjectCard = ({ project, index }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isProjectOpen, setIsProjectOpen] = useState(false);
  const [isFullProject, setIsFullProject] = useState(false);

  useEffect(() => {
    if (isTransitioning) {
      const timer = setTimeout(() => {
        setIsProjectOpen(true);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);

  const handleClick = (e) => {
    e.preventDefault();
    setIsTransitioning(true);
  };

  const handleBack = () => {
    setIsProjectOpen(false);
    setIsTransitioning(false);
    setIsFullProject(false);
  };

  const handleFullProject = () => {
    setIsFullProject(true);
  };

  return (
    <motion.div
      className="relative w-full h-96"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <AnimatePresence>
        {!isTransitioning && !isProjectOpen && (
          <motion.div
            className="w-full h-full"
            animate={{ rotateY: isFlipped ? 180 : 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            style={{ transformStyle: 'preserve-3d' }}
            onHoverStart={() => setIsFlipped(true)}
            onHoverEnd={() => setIsFlipped(false)}
            exit={{ opacity: 0, scale: 0.8, transition: { duration: 0.3 } }}
          >
            {/* Front side */}
            <motion.div
              className="absolute w-full h-full backface-hidden rounded-lg shadow-lg overflow-hidden bg-gray-800 border-2 border-green-400"
              style={{ backfaceVisibility: 'hidden' }}
            >
              <img src={project.image} alt={project.title} className="w-full h-64 object-cover" />
              <h3 className="text-lg font-mono font-semibold mt-2 p-4 text-green-400">
                <GlitchText text={project.title} />
              </h3>
            </motion.div>

            {/* Back side */}
            <motion.div 
              className="absolute w-full h-full backface-hidden rounded-lg shadow-lg overflow-hidden bg-gray-900 border-2 border-green-400 p-6 flex flex-col justify-between"
              style={{ backfaceVisibility: 'hidden', transform: 'rotateY(180deg)' }}
            >
              <div>
                <h3 className="text-xl font-mono font-bold mb-4 text-green-400">
                  <GlitchText text={project.title} />
                </h3>
                <p className="text-gray-300 mb-4">{project.description}</p>
              </div>
              <div>
                <h4 className="text-sm font-semibold mb-2 text-green-400">Made by:</h4>
                <ul className="list-disc list-inside text-sm text-gray-300">
                  {project.contributors.map((contributor, i) => (
                    <li key={i}>{contributor}</li>
                  ))}
                </ul>
                <motion.button
                  className="mt-4 inline-block bg-green-400 text-gray-900 py-2 px-4 rounded hover:bg-green-300 transition-colors duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleClick}
                >
                  View Project
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      
      <AnimatePresence>
        {(isTransitioning || isProjectOpen) && (
          <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.img
              src={project.image}
              alt={project.title}
              className="object-cover"
              initial={{ width: '300px', height: '200px', borderRadius: '8px' }}
              animate={{ 
                width: isProjectOpen ? '100%' : '100vw', 
                height: isProjectOpen ? '40vh' : '100vh', 
                borderRadius: '0px',
                transition: { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }
              }}
            />
            <motion.div
              className="absolute inset-0 bg-green-400"
              initial={{ opacity: 0 }}
              animate={{ opacity: isProjectOpen ? 0 : 0.3 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            />
            <motion.h2
              className="absolute text-4xl font-bold text-white text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: isProjectOpen ? 0 : 1, y: 0 }}
              transition={{ delay: 0.7, duration: 0.5 }}
            >
              {project.title}
            </motion.h2>
            {isProjectOpen && (
              <motion.div
                className="absolute top-0 left-0 right-0 bg-gray-900 p-4 flex justify-between items-center"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.5 }}
              >
                <motion.button
                  className="text-green-400 flex items-center"
                  onClick={handleBack}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <ArrowLeft className="mr-2" />
                  Back to Projects
                </motion.button>
                <motion.button
                  className="text-green-400 flex items-center"
                  onClick={handleFullProject}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  See Full Project? <ExternalLink className="ml-2" />
                </motion.button>
                <h3 className="text-green-400 font-bold">{project.title}</h3>
              </motion.div>
            )}
            {isProjectOpen && !isFullProject && (
              <motion.iframe
                src={project.url}
                className="absolute inset-0 w-full h-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1, duration: 0.5 }}
                style={{ top: '60px', height: 'calc(100% - 60px)' }}
              />
            )}
            {isFullProject && (
              <motion.div
                className="absolute inset-0 bg-black"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <motion.div
                  className="w-full h-full bg-green-400"
                  initial={{ scale: 0, borderRadius: '50%' }}
                  animate={{ scale: 20, borderRadius: '0%' }}
                  transition={{ duration: 1.5, ease: "easeInOut" }}
                  onAnimationComplete={() => window.location.href = project.url}
                />
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const Projects = () => {
  return (
    <motion.section
      className="p-8 bg-gray-900 min-h-screen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <Helmet>
        <title>Projects - DeadCodeStudios</title>
      </Helmet>
      <motion.h2
        className="text-3xl font-bold mb-8 text-green-400"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.2, ease: "easeOut" }}
      >
        <GlitchText text="&gt; Unsere_Projekte_" />
      </motion.h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
        variants={{
          hidden: { opacity: 1 },
          visible: {
            opacity: 1,
            transition: { staggerChildren: 0.1 }
          }
        }}
        initial="hidden"
        animate="visible"
      >
        {projects.map((project, index) => (
          <ProjectCard key={index} project={project} index={index} />
        ))}
      </motion.div>
    </motion.section>
  );
};

export default Projects;